<template>
  <div class="box">
    <!-- 头部背景图 -->
    <div class="bgimg">
      <a href="javascript:;" class="applyimg" @click="goapply"></a>
      <!-- 如果您 -->
      <div class="content">
        <div>
          <p>市场下行压力持续加大，行业竞争愈来愈激烈，工程商找活难。</p>
          <p>
            有资源的集成商，活不少：外地活招人难，管理难、费用高、维保更难；多项目同时开工，找不到人。
          </p>
          <p>术业有专攻，不熟悉的系统施工交给更专业更有经验的人来做。</p>
          <span
            >厨盟寻找志同道合的合作伙伴，链接资源与服务，让业务承接不再是难事！</span
          >
        </div>
      </div>
    </div>
    <div class="header">
      <!-- 服务合伙人介绍 -->
      <div class="header-1">
        <div class="title">
          <h3>服务合伙人介绍</h3>
        </div>
        <div class="content">
          <h3>厨盟服务合伙人</h3>
          <p>
            <i></i>
            <span
              >厨盟拥有几十万认证用户，项目遍布全国，服务需求巨大，急需一批服务合伙人和我们共同服务。</span
            >
          </p>
          <p>
            <i></i>
            <span
              >合伙人与平台合作，不但解决“更多业务”的问题，更能解决“收款难”的难题。</span
            >
          </p>
          <p>
            <i></i>
            <span
              >合伙人所在区域优先指派服务单，享重大项目与工程的优先推荐。加盟我们，业务兜底，保障到位。</span
            >
          </p>
        </div>
      </div>
      <!-- 加盟要求  -->
      <div class="header-2">
        <div class="title">
          <h3>加盟要求</h3>
        </div>
        <div class="content">
          <div>
            <img src="../../assets/images/photo/partner4/16.png" alt="" />
            <p>全国范围内，弱电工程安装、调试、施工团队及个人</p>
          </div>
          <div>
            <img src="../../assets/images/photo/partner4/17.png" alt="" />
            <p>具有专业技能、证书，丰富的现场工作经验者优先</p>
          </div>
          <div>
            <img src="../../assets/images/photo/partner4/18.png" alt="" />
            <p>具有良好的契约精神，诚信为本，规范施工</p>
          </div>
          <div>
            <img src="../../assets/images/photo/partner4/19.png" alt="" />
            <p>拥有较强的服务意识，能够深入了解客户实际需求</p>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <!-- 平台保障与优势  -->
      <div class="center-1">
        <div class="title">
          <h3>平台保障与优势</h3>
        </div>
        <div class="content">
          <div>
            <div class="title">
              <h3>合伙人五大保障，让业务承接不再是难事</h3>
            </div>
            <div class="content">
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/15.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>数量保障</h4>
                  <p>保证每年一定的项目服务数量，不为没有工程而困扰</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/1.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>优先派单</h4>
                  <p>周边城市最新施工服务第一时间推送，且优先接单</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/3.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>全程跟踪</h4>
                  <p>每日上传工作进度照片，客户实时查看，免去施工中的扯皮</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/14.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>对接客户</h4>
                  <p>平台出具《现场服务单》，由客户签字认可，省去反复整改</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/1.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>平台担保</h4>
                  <p>施工完成后按照指定时间结算佣金</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="title">
              <h3>五大平台优势，助您拿下好订单</h3>
            </div>
            <div class="content-1">
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/10.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>保障一</h4>
                  <p>优先派单技术变现</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/10.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>保障二</h4>
                  <p>闲暇时间灵活自由</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/10.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>保障三</h4>
                  <p>资金保障多劳多得</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/10.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>保障四</h4>
                  <p>信息真实 平台审核</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner4/10.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>保障五</h4>
                  <p>服务规范信用评价</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- 申请流程 -->
      <div class="title-box">
        <div class="title">
          <h3>申请流程</h3>
        </div>
        <div class="content">
          <img src="../../assets/images/photo/partner4/8.png" alt="" />
        </div>
      </div>
      <!-- 申请加盟 -->
      <div class="bottom-2">
        <div class="title" id="apply">
          <h3>申请加盟</h3>
        </div>
        <div class="apply-content">
          <h3>填写加盟信息</h3>
          <div>
            <partnerform-1 :type="type"></partnerform-1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnerform1 from "./partnerform1.vue";
export default {
  props: ["active"],
  components: { partnerform1 },
  data() {
    return {};
  },
  computed: {
    type() {
      // 如果传入active值为5则为服务合伙人type值为3，如果为6则为设计师type值为4
      let type = "";
      if (this.active == 5) {
        type = 3;
      }
      return type;
    },
  },
  created() {
    console.log(this.active);
  },
  methods: {
    goapply() {
      this.$el.querySelector(`#apply`).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "center", // 上边框与视窗顶部平齐。默认值
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin title-left {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("../../assets/images/photo/partner4/left.png") no-repeat;
  margin-right: 20px;
}
@mixin title-right {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("../../assets/images/photo/partner4/right.png") no-repeat
    center;
  margin-left: 20px;
}
@mixin title {
  color: #222222;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}
.box {
  .bgimg {
    position: relative;
    width: 100%;
    height: 600px;
    margin-bottom: 250px;
    background: url("../../assets/images/photo/partner4/6.jpg") no-repeat
      center top;
    & > .applyimg {
      display: inline-block;
      width: 294px;
      height: 64px;
      background: url("../../assets/images/photo/partner4/9.png")
        no-repeat;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      top: 335px;
      left: 50%;
      margin-left: -147px;
    }
    & > .content {
      display: inline-block;
      width: 1190px;
      height: 329px;
      background: url("../../assets/images/photo/partner4/12.jpg")
        no-repeat;
      position: absolute;
      padding-top: 70px;
      top: 436px;
      left: 50%;
      margin-left: -595px;
      div {
        padding: 0 100px 48px;
        p {
          font-size: 20px;
          color: #9d7832;
          font-weight: bold;
          text-align: left;
          line-height: 26px;
          margin-bottom: 26px;
        }
        span {
          display: inline-block;
          font-size: 20px;
          color: #222222;
          text-align: left;
          line-height: 1;
          font-weight: bold;
          padding-top: 12px;
        }
      }
    }
  }
  .header {
    width: 1190px;
    margin: 0 auto;
    .header-1 {
      .title {
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        width: 1125px;
        background: url("../../assets/images/photo/partner4/11.png")
          no-repeat;
        height: 400px;
        margin-top: 30px;
        padding: 60px 0 0 85px;
        h3 {
          display: inline-block;
          line-height: 1;
          font-size: 26px;
          color: #9d7832;
          font-weight: bold;
          margin-bottom: 40px;
        }
        p {
          padding-right: 620px;
          font-size: 20px;
          color: #222222;
          line-height: 40px;
          text-align: left;
          i {
            display: inline-block;
            width: 10px;
            height: 40px;
            background: url("../../assets/images/photo/partner4/4.png")
              no-repeat left center;
            vertical-align: 26px;
          }
          span {
            display: inline-block;
            width: calc(100% - 36px);
            padding-left: 26px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .header-2 {
      .title {
        margin-top: 70px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        margin-top: 30px;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        & > div {
          vertical-align: top;
          text-align-last: center;
          display: inline-block;
          width: 290px;
          height: 208px;
          margin-right: 10px;
          img {
            margin-top: 36px;
          }
          p {
            color: #fff;
            font-size: 16px;
            text-align: center;
            font-weight: bold;
            line-height: 30px;
            padding: 0 37px;
            margin-top: 11px;
          }
        }
        & > div:nth-child(1) {
          background: url("../../assets/images/photo/partner4/2.png")
            no-repeat;
        }
        & > div:nth-child(2) {
          background: url("../../assets/images/photo/partner4/13.png")
            no-repeat;
        }
        & > div:nth-child(3) {
          background: url("../../assets/images/photo/partner4/5.png")
            no-repeat;
        }
        & > div:nth-child(4) {
          background: url("../../assets/images/photo/partner4/7.png")
            no-repeat;
        }
      }
    }
  }
  .center {
    background: url("http://q5355.bvimg.com/10063/09e2849fccf0d809.jpg") no-repeat
      center top;
    margin-top: 50px;
    padding-bottom: 54px;
    .center-1 {
      width: 1190px;
      margin: 0 auto;
      .title {
        padding-top: 60px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      & > .content {
        width: 1189px;
        height: 526px;
        background: url("http://q5355.bvimg.com/10063/4e56c0d1e273c07a.png")
          no-repeat;
        margin-top: 18px;
        display: flex;
        & > div {
          width: 50%;
          box-sizing: border-box;
          & > div:nth-child(1) {
            text-align: center;
            & > h3 {
              @include title;
              font-size: 22px;

              &::before {
                @include title-left;
                width: 19px;
                height: 26px;
                background: url("http://i2.tiimg.com/671541/2c43400683b0b952.png")
                  no-repeat center 18px;
              }
              &::after {
                @include title-right;
                width: 19px;
                height: 26px;
                background: url("http://i2.tiimg.com/671541/2c43400683b0b952.png")
                  no-repeat center 18px;
              }
            }
          }
          .content {
            padding-top: 40px;
            & > div {
              display: flex;
              height: 46px;
              margin-bottom: 30px;
              padding-left: 30px;
              & > div:nth-child(2) {
                padding-left: 22px;
                h4 {
                  font-size: 18px;
                  color: #9d7832;
                  font-weight: bold;
                  line-height: 1;
                  padding-top: 2px;
                }
                p {
                  font-size: 18px;
                  color: #000000;
                  line-height: 1;
                  margin-top: 8px;
                }
              }
            }
          }
          .content-1 {
            padding-top: 40px;
            & > div {
              display: flex;
              height: 46px;
              margin-bottom: 30px;
              padding-left: 140px;
              & > div:nth-child(2) {
                padding-left: 22px;
                h4 {
                  display: inline-block;
                  font-size: 18px;
                  color: #9d7832;
                  font-weight: bold;
                  line-height: 1;
                  padding-top: 2px;
                  margin-right: 16px;
                }
                p {
                  display: inline-block;
                  font-size: 18px;
                  color: #000000;
                  line-height: 1;
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 1190px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 40px;
    .title-box {
      text-align: center;
      .title {
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        margin-top: 20px;
      }
    }
    .bottom-2 {
      .title {
        margin-top: 50px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .apply-content {
        border: 1px solid #f2e2c4;
        margin-top: 30px;
        h3 {
          text-align: left;
          padding-left: 29px;
          height: 48px;
          line-height: 48px;
          color: #222222;
          font-size: 20px;
          border-bottom: 1px solid #f2e2c4;
          background: #f9f2e7;
        }
        & > div {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
  }
}
</style>