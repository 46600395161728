<template>
  <div class="header">
    <div class="logo">
      <img
        class="avater-logo"
        src="../../assets/images/timg.jpg"
        @click="$router.push('/index')"
      />
      <span>合伙人招募</span>
    </div>
    <div class="navbar">
      <span :class="{ active: active == 0 }" @click.stop="gopage(0)">首页</span>
      <span
        v-if="type == 1"
        :class="{ active: active == 1 }"
        @click.stop="gopage(1)"
        >厂家/经销商注册</span
      >
      <span
        v-if="type == 1"
        :class="{ active: active == 2 }"
        @click.stop="gopage(2)"
        >餐饮公司注册</span
      >
      <span
        v-if="type != 1 && type != 'kehu'"
        :class="{ active: active == 4 }"
        @click.stop="gopage(4)"
        >项目合伙人</span
      >
      <span
        v-if="type != 1 && type != 'kehu'"
        :class="{ active: active == 3 }"
        @click.stop="gopage(3)"
        >城市合伙人</span
      >

      <span
        v-if="type != 1 && type != 'kehu'"
        :class="{ active: active == 5 }"
        @click.stop="gopage(5)"
        >服务合伙人</span
      >
      <span
        v-if="type != 1 && type != 'kehu'"
        :class="{ active: active == 6 }"
        @click.stop="gopage(6)"
        >设计师</span
      >
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  props: ["active", "type"],
  data() {
    return {};
  },
  methods: {
    gopage(num) {
      console.log(num);
      console.log(this.active);
      console.log("typetype", this.type);
      this.$emit("goapply");
      num == 0 ? this.$router.push("/index") : this.$emit("updateActive", num);
    },
  },
  computed: {
    index() {
      return this.active;
    },
  },
};
</script>

<style lang="scss" scoped>
$header: 96px;
.header {
  height: $header;
  position: fixed;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  min-width: 800px;
  background: #fff;
  z-index: 10;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  .logo {
    height: 100%;
    flex: 4;
    color: #404040;
    font-size: 25px;
    font-weight: 600;
    text-shadow: 0px 0px 1px;
    .avater-logo {
      height: 100%;
      vertical-align: middle;
      margin-left: 30%;
    }
  }
  .navbar {
    flex: 6;
    line-height: $header;
    display: flex;
    box-sizing: border-box;
    padding: 0;
    span {
      margin-right: 40px;
      text-align: center;
      color: #222222;
      font-size: 18px;
      font-weight: bold;
      position: relative;
    }
  }
}
.active {
  &::after {
    content: "";
    display: inline-block;
    width: 36px;
    height: 2px;
    background: #e2bc6d;
    position: absolute;
    bottom: 27px;
    left: 50%;
    margin-left: -18px;
  }
}
</style>