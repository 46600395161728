<template>
  <div>
    <div class="box">
      <div class="box-card">
        <div>
          <!-- <span
            v-text="
              state == 'add'
                ? '添加信息'
                : state == 'update'
                ? '修改信息'
                : state == 'anew'
                ? '重新提交信息'
                : ''
            "
          ></span> -->
          <span>添加信息</span>
          <el-button type="primary" @click="getMyaudit">已提交信息</el-button>
        </div>

        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="地区" prop="region">
            <el-cascader
              ref="cascaderAddr"
              placeholder="请选择地区"
              v-model="ruleForm.region"
              :options="options"
              @change="regionhandleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="message">
            <el-input v-model="ruleForm.message"></el-input>
          </el-form-item>
          <el-form-item label="采购类型" prop="type" v-if="type == 1">
            <el-select v-model="ruleForm.type" placeholder="请选择采购类型">
              <el-option label="商厨采购" value="1"></el-option>
              <el-option label="洗化食材采购" value="2"></el-option>
              <el-option label="酒店用品采购" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务类型" prop="type" v-if="type == 3">
            <el-select v-model="ruleForm.type" placeholder="请选择服务类型">
              <el-option label="售后" value="1"></el-option>
              <el-option label="配送" value="2"></el-option>
              <el-option label="厨师长" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              v-text="'立即添加'"
            ></el-button>
            <!-- <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              v-text="
                state == 'add'
                  ? '立即添加'
                  : state == 'update'
                  ? '立即修改'
                  : state == 'anew'
                  ? '重新提交'
                  : ''
              "
            ></el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      title="我的提交信息"
      :visible.sync="dialogTableVisible"
      class="Myaudit"
      width="70%"
    >
      <!-- <div>
        <el-button type="primary" @click="updateMyaudit">修改</el-button>
        <el-button type="primary" @click="anewMyaudit">重新提交</el-button>
        <el-button type="danger" @click="delectMyaudit">删除</el-button>
      </div> -->
      <div></div>
      <div><span>姓名:</span><span v-text="Myaudit.name"></span></div>
      <div><span>电话:</span><span v-text="Myaudit.phone"></span></div>
      <div><span>备注:</span><span v-text="Myaudit.message"></span></div>
      <div>
        <span>申请类型:</span><span>{{ Myaudit.checkType | checkType }}</span>
      </div>
      <div v-if="Myaudit.checkType == 1">
        <span>采购类型:</span><span>{{ Myaudit.type | type }}</span>
      </div>
      <div>
        <span>审核状态:</span><span>{{ Myaudit.status | status }}</span>
      </div>
      <div v-if="Myaudit.status == 2">
        <span>拒绝原因:</span><span v-text="Myaudit.failMessage"></span>
      </div>
      <div>
        <span>所在地区:</span
        ><span
          v-text="`${Myaudit.provinceId}${Myaudit.cityId}${Myaudit.countyId}`"
        ></span>
      </div>
      <div><span>创建时间:</span><span v-text="Myaudit.createTime"></span></div>
      <div><span>修改时间:</span><span v-text="Myaudit.updateTime"></span></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  props: ["type"],
  data() {
    return {
      // 记录当前处于添加/删除/更新
      state: "add",
      // 地区
      options: JSON.parse(sessionStorage.getItem("allAreaData")),
      // 我的公司审核信息模块显示隐藏
      dialogTableVisible: false,
      // 我的公司审核信息
      Myaudit: {},
      fileList: [],
      ruleForm: {
        name: "",
        region: "",
        message: "",
        phone: "",
        type: "",
        checkType: this.type,
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        message: [{ required: true, message: "请输入备注", trigger: "blur" }],
        phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
        region: [{ required: true, message: "请选择地区", trigger: "change" }],
        type: [
          { required: true, message: "请选择采购类型", trigger: "change" },
        ],
      },
    };
  },
  filters: {
    status(status) {
      if (status == 0) {
        return "待审核";
      } else if (status == 1) {
        return "成功";
      } else if (status == 2) {
        return "失败";
      }
    },
    checkType(checkType) {
      if (checkType == 1) {
        return "项目合伙人";
      } else if (checkType == 2) {
        return "城市合伙人";
      } else if (checkType == 3) {
        return "服务合伙人";
      } else if (checkType == 4) {
        return "设计师";
      }
    },
    type(type) {
      if (type == 1) {
        return "商厨采购";
      } else if (type == 2) {
        return "洗化食材采购";
      } else if (type == 3) {
        return "酒店用品采购";
      }
    },
  },
  methods: {
    // 提交表单
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 如果当前选中餐饮公司则提交表单的type为4
          // 如果当前选中城市合伙人提交表单的type为5
          let ruleForm = { ...this.ruleForm };
          ruleForm.provinceId = ruleForm.region[0];
          ruleForm.cityId = ruleForm.region[1];
          ruleForm.countyId = ruleForm.region[2];
          delete ruleForm.region;
          // 如果不是项目合伙人或者服务合伙人则删除ruleForm.type
          if (ruleForm.checkType != 1 && ruleForm.checkType != 3) {
            delete ruleForm.type;
          }
          if (this.state == "add") {
            const res = await this.$http({
              method: "post",
              url: "front/checkPartner/submitApplication",
              data: {
                ...ruleForm,
              },
            });

            if (res.data.status == 200) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
            }
          }
          //   else if (this.state == "update") {
          //     const res = await this.$http({
          //       method: "post",
          //       url: "front/checkCompany/update",
          //       data: {
          //         id: this.Myaudit.id,
          //         ...ruleForm,
          //       },
          //     });
          //     if (res.data.status == 200) {
          //       this.$message({
          //         message: "修改成功",
          //         type: "success",
          //       });
          //     }
          //   }
          else if (this.state == "anew") {
            const res = await this.$http({
              method: "post",
              url: "front/checkPartner/submitApplication",
              data: {
                // id: this.Myaudit.id,
                ...ruleForm,
              },
            });

            if (res.data.status == 200) {
              this.$message({
                message: "重新提交成功",
                type: "success",
              });
            }
          }
        }
      });
    },
    // 修改我的公司审核信息
    // updateMyaudit() {
    //   this.dialogTableVisible = false;
    //   this.state = "update";
    //   this.ruleForm = this.Myaudit;
    //   this.ruleForm.region = [];
    //   // this.reload();
    // },
    // 更新我的公司审核信息
    // anewMyaudit() {
    //   this.dialogTableVisible = false;
    //   this.state = "anew";
    //   this.ruleForm = this.Myaudit;
    //   this.ruleForm.region = [];
    //   // this.reload();
    // },
    // 删除我的公司审核信息
    // async delectMyaudit() {
    //   const res = await this.$http({
    //     method: "post",
    //     url: "front/checkCompany/delete",
    //     data: {
    //       id: this.Myaudit.id,
    //     },
    //   });
    //   if (res.data.status == 200) {
    //     this.reload();
    //     this.dialogTableVisible = false;
    //     this.$message({
    //       message: "删除成功",
    //       type: "success",
    //     });
    //   }
    // },
    //获取我的公司审核信息
    async getMyaudit() {
      const res = await this.$http({
        method: "get",
        url: "front/checkPartner/getMyApplication",
      });
      if (res.data.status == 200) {
        this.dialogTableVisible = true;
        this.Myaudit = res.data.data;
      }
    },
    // 地区选择change
    regionhandleChange(value) {
      // 显示lable
      // console.log(this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels);
    },
  },
  created() {
    console.log("tyep", this.type);
  },
};
</script>

<style lang="scss" scoped>
@mixin title {
  font-size: 18px;
  margin: 10px;
}
.el-dialog__wrapper {
  min-width: 1000px;
}
.box {
  width: 100%;
  min-width: 1000px;
  .box-card {
    width: 50%;
    margin: 0 auto;
    & ::v-deep .el-button {
      display: inline-block;
      color: #ffffff;
      font-size: 18px;
      width: 192px;
      height: 48px;
      background: url(https://image01.homedo.com/Files/Images/cms/www/20190828/5448770380995588460_pic1.png)
        no-repeat;
      text-align: center;
      margin: 0 auto;
      outline: none;
      border: 0;
    }
    & ::v-deep .el-input__inner {
      border: 1px solid #f2e2c4;
    }
    & > div:nth-child(1) {
      display: flex;
      justify-content: space-between;
      span {
        font-weight: 600;
        line-height: 50px;
      }
      .el-button {
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
    .el-card__body {
      p:nth-child(2) {
        @include title;
      }
    }
  }
}
.Myaudit {
  div {
    display: flex;
    align-items: center;
    padding: 5px;
    span:nth-child(1) {
      display: inline-block;
      width: 150px;
      text-align: right;
    }
    span:nth-child(2) {
      display: inline-block;
      margin-left: 20px;
    }
    img {
      width: 400px;
      margin-left: 20px;
    }
  }
  div:nth-child(1) {
    display: flex;
    justify-content: flex-end;
  }
}
</style>