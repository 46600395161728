<template>
  <div>
    <!-- 回到顶部锚点 -->
    <i id="box"></i>
    <partner-header
      :active="active"
      :type="type"
      @goapply="goapply"
      @updateActive="updateActive"
    >
    </partner-header>
    <div class="box">
      <!-- 厂家/经销商注册 -->
      <partner-2 :active="active" v-if="active == 1"></partner-2>
      <!-- 餐饮公司注册 -->
      <partner-22 :active="active" v-if="active == 2"></partner-22>
      <!-- 客户 -->
      <partner-33 :active="active" v-if="active == 'kehu'"></partner-33>
      <!-- 城市合伙人 -->
      <partner-1 :active="active" v-if="active == 3"></partner-1>
      <!-- 项目合伙人 -->
      <partner-3 :active="active" v-if="active == 4"></partner-3>
      <!-- 服务合伙人 -->
      <partner-4 :active="active" v-if="active == 5"></partner-4>
      <!-- 设计师 -->
      <partner-5 :active="active" v-if="active == 6"></partner-5>
    </div>
  </div>
</template>

<script>
import Partner1 from "./partner1.vue";
import Partner2 from "./partner2.vue";
import Partner22 from "./partner2.vue"; //餐饮
import Partner33 from "./partner2.vue"; //客户
import Partner3 from "./partner3.vue";
import Partner4 from "./partner4.vue";
import Partner5 from "./partner5.vue";
import partnerHeader from "./partnerHeader";
export default {
  components: {
    partnerHeader,
    Partner1,
    Partner2,
    Partner22,
    Partner33,
    Partner3,
    Partner4,
    Partner5,
  },
  data() {
    return {
      active: "",
      type: "",
    };
  },
  methods: {
    // 根据传参不同显示不同页面
    getquery() {
      this.type = JSON.parse(this.$route.query.type);
      this.active = JSON.parse(this.$route.query.type);
    },
    // 切换页面
    updateActive(num) {
      this.active = num;
    },
    // 过渡效果
    goapply() {
      this.$el.querySelector(`#box`).scrollIntoView({
        // behavior: "smooth", // 平滑过渡
        block: "center", // 上边框与视窗顶部平齐。默认值
      });
    },
  },
  created() {
    this.getquery();
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-top: 96px;
}
</style>