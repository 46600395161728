<template>
  <div class="box">
    <!-- 头部背景图 -->
    <div class="bgimg">
      <a href="javascript:;" class="applyimg" @click="goapply"></a>
    </div>
    <!-- 介绍模块 -->
    <div class="header">
      <div>
        <div class="title">厂商加入介绍</div>
        <div class="content">
          <h4>厂商合伙人在授权区域范围内</h4>
          <h3>独家代表厨盟开展平台各类业务板块工作</h3>
          <p>
            如：推广平台、发展新用户、开展项目对接运作,<br />以及发展区域范围内各类资源合作伙伴。
          </p>
          <div class="img-box">
            <img src="../../assets/images/photo/partner1/15.png" alt="" />
            <img src="../../assets/images/photo/partner1/14.png" alt="" />
            <img src="../../assets/images/photo/partner1/21.png" alt="" />
            <img src="../../assets/images/photo/partner1/24.png" alt="" />
          </div>
        </div>
      </div>
      <div>
        <div class="title">我们要做什么</div>
        <div class="content">
          <h4>我们要打造产业互联网+区域战略合作伙伴的</h4>
          <h3>S2B新商贸模式</h3>
          <p>
            为来自中小型城市的工程集成商和设备销售商搭建平台，<br />助力技术变现、资源变现、人力变现，赚钱更轻松。
          </p>
          <div class="img-box">
            <img src="../../assets/images/photo/partner1/20.png" alt="" />
            <img src="../../assets/images/photo/partner1/18.png" alt="" />
            <img src="../../assets/images/photo/partner1/19.png" alt="" />
            <img src="../../assets/images/photo/partner1/17.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="center-box">
        <!-- 权益保障模块 -->
        <div class="center-content">
          <div class="title">
            <h4>五大权益与五大保障</h4>
          </div>
          <div class="detail">
            <div>
              <p>
                <img
                  src="../../assets/images/photo/partner1/1.png"
                  alt=""
                />
                <span>权益一</span>
                <span>区域资源独占，高额回报</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/10.png"
                  alt=""
                />
                <span>权益二</span>
                <span>有权申请股权，合作共赢</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/5.png"
                  alt=""
                />
                <span>权益三</span>
                <span>对外代表平台，对接本地资源，分享经营成果</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/7.png"
                  alt=""
                />
                <span>权益四</span>
                <span>享有线上线下资源，轻松结交潜在客户</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/8.png"
                  alt=""
                />
                <span>权益五</span>
                <span>依靠厨盟平台，扩大区域影响力</span>
              </p>
            </div>
            <div>
              <p>
                <img
                  src="../../assets/images/photo/partner1/9.png"
                  alt=""
                />
                <span>权益一</span>
                <span>平台正式授权与培训</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/3.png"
                  alt=""
                />
                <span>权益二</span>
                <span>区域线下服务中心</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/2.png"
                  alt=""
                />
                <span>权益三</span>
                <span>独家代表厨盟开展平台各项业务</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/11.png"
                  alt=""
                />
                <span>权益四</span>
                <span>享有技术、销售、客拓等全方位支持</span>
              </p>
              <p>
                <img
                  src="../../assets/images/photo/partner1/4.png"
                  alt=""
                />
                <span>权益五</span>
                <span>参与厨盟所有线下活动</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 招募模块 -->
        <div class="recruit">
          <h3>招募范围</h3>
          <h4>有经营能力、有行业资源、有专职团队的合作伙伴</h4>
          <p>除省会城市和直辖市以外的其他城市，每座城市发展独家</p>
          <a href="javascript:;">详见已招区域表</a>
        </div>
        <!-- 申请流程 -->
        <div class="flowPath">
          <h3>申请流程</h3>
          <img
            src="../../assets/images/photo/partner1/6.png"
            alt=""
          />
        </div>
        <!-- 申请加盟 -->
        <div class="apply" id="apply">
          <div class="title">
            <h3>申请加盟</h3>
          </div>
        </div>
        <!-- 申请加盟内容 -->
        <div class="apply-content">
          <h3>填写加盟信息</h3>
          <p v-if="activeType">
            <el-link
              type="warning"
              :style="{ marginLeft: '80%' }"
              @click="activeType = false"
              >重新选择身份</el-link
            >
          </p>
          <div class="active" v-if="!activeType">
            <p>请选择您的身份</p>
            <p>
              <span @click="active(1)">个人</span>
              <span @click="active(2)">企业</span>
            </p>
          </div>
          <div>
            <partnerform
              v-if="activeType && status == 2"
              :type="5"
            ></partnerform>
            <partnerform-1
              v-if="activeType && status == 1"
              :type="2"
            ></partnerform-1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnerform from "./partnerform.vue";
import Partnerform1 from "./partnerform1.vue";
export default {
  components: { partnerform, Partnerform1 },
  data() {
    return {
      activeType: false, //选择身份组件显示
      status: Number, //根据选择身份显示相应表单
    };
  },
  methods: {
    goapply() {
      this.$el.querySelector(`#apply`).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "center", // 上边框与视窗顶部平齐。默认值
      });
    },
    // 选择身份
    active(status) {
      this.activeType = true;
      this.status = status;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin box-top {
  content: "";
  background: url("http://i2.tiimg.com/671541/8f263f7d12120f9c.jpg") no-repeat
    center top;
  display: inline-block;
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
}
@mixin box-bottom {
  content: "";
  background: url("http://i2.tiimg.com/671541/61a9a4072c7aefb8.jpg") no-repeat
    bottom;
  display: inline-block;
  width: 100%;
  height: 28px;
  position: absolute;
  bottom: 0px;
  left: 0;
}
@mixin title-left {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("../../assets/images/photo/partner4/left.png") no-repeat;
  margin-right: 20px;
}
@mixin title-right {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("../../assets/images/photo/partner4/right.png") no-repeat
    center;
  margin-left: 20px;
}
.active {
  text-align: center;
  & > p:nth-child(1) {
    line-height: 48px;
    color: #222222;
    font-size: 20px;
  }
  & > p:nth-child(2) {
    span {
      display: inline-block;
      color: #ffffff;
      font-size: 18px;
      width: 192px;
      height: 48px;
      background: url("https://image01.homedo.com/Files/Images/cms/www/20190828/5448770380995588460_pic1.png")
        no-repeat;
      text-align: center;
      margin: 20px 20px;
      outline: none;
      border: 0;
      line-height: 48px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
body {
  font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif, "Microsoft YaHei";
}
.box {
  .bgimg {
    width: 100%;
    height: 600px;
    background: url("../../assets/images/photo/partner1/12.jpg") no-repeat
      center top;
    & > .applyimg {
      display: inline-block;
      width: 294px;
      height: 64px;
      background: url("../../assets/images/photo/partner3/17.png")
        no-repeat;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      top: 334px;
      left: 50%;
      margin-left: -147px;
    }
  }
  .header {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 79%;
    margin: 50px auto;
    & > div {
      width: 585px;
      .title {
        width: 100%;
        text-align: center;
        line-height: 1;
        font-size: 32px;
        color: #222222;
        font-weight: bold;
        &::before {
          @include title-left;
        }
        &::after {
          @include title-right;
        }
      }
      .content {
        position: relative;
        background: url("../../assets/images/photo/partner1/16.png")
          repeat-y center;
        margin-top: 30px;
        padding-top: 20px;
        &::before {
          @include box-top;
          background: url("../../assets/images/photo/partner1/22.jpg")
            no-repeat;
        }
        &::after {
          @include box-bottom;
          background: url("../../assets/images/photo/partner1/22.jpg")
            no-repeat;
        }
        font-size: 20px;
        line-height: 1;
        text-align: left;
        padding: 28px 47px 0px 47px;
        color: #222222;
        h4 {
          margin-top: 14px;
          font-weight: normal;
        }
        h3 {
          color: #9d7832;
          font-weight: bold;
          margin-top: 21px;
          margin-bottom: 23px;
        }
        p {
          font-size: 16px;
          line-height: 32px;
        }
        .img-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-top: 37px;
          padding-bottom: 40px;
          img {
            width: 21.8%;
          }
        }
      }
    }
  }
  .center {
    background: url("../../assets/images/photo/partner1/23.jpg") no-repeat
      center top;
    padding-bottom: 40px;
    .center-box {
      
      width: 1190px;
      margin: 0 auto;
      .center-content {
        .title {
          text-align: center;
          h4 {
            padding-top: 24px;
            color: #222222;
            font-size: 32px;
            line-height: 1;
            font-weight: bold;
            display: inline-block;

            &::before {
              @include title-left;
              
            }
            &::after {
              @include title-right;
            }
          }
        }
        .detail {
          display: flex;
          width: 1232px;
          height: 456px;
          
          margin-top: 30px;
          margin-left: -21px;
          div {
            margin-top: 25px;
            font-size: 18px;
            color: #222222;
            p {
              text-align: left;
              margin-top: 24px;
              box-sizing: border-box;
              padding-left: 68px;
              span:nth-child(2) {
                margin-left: 20px;
                font-size: 20px;
                color: #9d7832;
                margin-right: 11px;
              }
              span:nth-child(3) {
                height: 46px;
                line-height: 46px;
              }
            }
            img {
              vertical-align: middle;
            }
          }
        }
      }
      .recruit {
        margin-top: 85px;
        display: inline-block;
        width: 100%;
        background: url("../../assets/images/photo/partner1/13.jpg")
          no-repeat;
        padding: 60px 70px 61px;
        box-sizing: border-box;
        h3 {
          color: #e1bb6c;
          font-size: 32px;
          font-weight: bold;
          line-height: 1;
          text-align: left;
          &::before {
            @include title-left;
            background: url("../../assets/images/photo/partner1/left.png");
          }
          &::after {
            @include title-right;
            background: url("../../assets/images/photo/partner1/right.png");
          }
        }
        h4 {
          color: #ffffff;
          font-size: 26px;
          line-height: 1;
          text-align: left;
          margin-top: 34px;
        }
        p {
          color: #ffffff;
          font-size: 20px;
          line-height: 1;
          text-align: left;
          margin-top: 40px;
        }
        a {
          color: #e1bb6c;
          font-size: 14px;
          line-height: 1;
          text-align: left;
          margin-top: 43px;
          display: inline-block;
          text-decoration: underline;
        }
      }
      .flowPath {
        padding-top: 90px;
        width: 1190px;
        margin: 0 auto;
        text-align: center;
        h3 {
          color: #222222;
          font-size: 32px;
          line-height: 1;
          font-weight: bold;
          display: inline-block;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
        img {
          margin: 20px 0px;
        }
      }
      .apply {
        text-align: center;
        margin-top: 30px;
        .title {
          h3 {
            color: #222222;
            font-size: 32px;
            line-height: 1;
            font-weight: bold;
            display: inline-block;
            &::before {
              @include title-left;
            }
            &::after {
              @include title-right;
            }
          }
        }
      }
      .apply-content {
        border: 1px solid #f2e2c4;
        margin-top: 30px;
        h3 {
          text-align: left;
          padding-left: 29px;
          height: 48px;
          line-height: 48px;
          color: #222222;
          font-size: 20px;
          border-bottom: 1px solid #f2e2c4;
          background: #f9f2e7;
        }
        & > div {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
  }
}
</style>