<template>
  <div class="box">
    <div class="bottom">
      <!-- 申请加盟 -->
      <div
        class="bottom-2"
        :style="{ marginTop: active == 6 ? '130px' : '0px' }"
      >
        <div class="title" id="apply">
          <h3>申请成为设计师</h3>
        </div>
        <div class="apply-content">
          <h3>填写设计师信息</h3>
          <div>
            <partnerform-1 :type="type"></partnerform-1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnerform1 from "./partnerform1.vue";
export default {
  props: ["active"],
  components: { partnerform1 },
  data() {
    return {};
  },
  computed: {
    type() {
      // 如果传入active值为5则为服务合伙人type值为3，如果为6则为设计师type值为4
      let type = "";
      if (this.active == 6) {
        type = 4;
      }
      return type;
    },
  },
  created() {
    console.log(this.active);
  },
  methods: {
    goapply() {
      this.$el.querySelector(`#apply`).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "center", // 上边框与视窗顶部平齐。默认值
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin title-left {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("http://i2.tiimg.com/671541/5ad394172f195fbf.png") no-repeat;
  margin-right: 20px;
}
@mixin title-right {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("http://i2.tiimg.com/671541/5ad394172f195fbf.png") no-repeat
    center;
  margin-left: 20px;
}
@mixin title {
  color: #222222;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}
.box {
  .bgimg {
    position: relative;
    width: 100%;
    height: 600px;
    margin-bottom: 250px;
    background: url("http://i2.tiimg.com/671541/a3d1171241226318.jpg") no-repeat
      center top;
    & > .applyimg {
      display: inline-block;
      width: 294px;
      height: 64px;
      background: url("http://i2.tiimg.com/671541/f1d5ea031e0b0813.png")
        no-repeat;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      top: 335px;
      left: 50%;
      margin-left: -147px;
    }
    & > .content {
      display: inline-block;
      width: 1190px;
      height: 329px;
      background: url("http://i1.fuimg.com/671541/655aa7e9eed6fcac.jpg")
        no-repeat;
      position: absolute;
      padding-top: 70px;
      top: 436px;
      left: 50%;
      margin-left: -595px;
      div {
        padding: 0 100px 48px;
        p {
          font-size: 20px;
          color: #9d7832;
          font-weight: bold;
          text-align: left;
          line-height: 26px;
          margin-bottom: 26px;
        }
        span {
          display: inline-block;
          font-size: 20px;
          color: #222222;
          text-align: left;
          line-height: 1;
          font-weight: bold;
          padding-top: 12px;
        }
      }
    }
  }
  .header {
    width: 1190px;
    margin: 0 auto;
    .header-1 {
      .title {
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        width: 1125px;
        background: url("http://i1.fuimg.com/671541/0bb0e98386c3b89a.png")
          no-repeat;
        height: 400px;
        margin-top: 30px;
        padding: 60px 0 0 85px;
        h3 {
          display: inline-block;
          line-height: 1;
          font-size: 26px;
          color: #9d7832;
          font-weight: bold;
          margin-bottom: 40px;
        }
        p {
          padding-right: 620px;
          font-size: 20px;
          color: #222222;
          line-height: 40px;
          text-align: left;
          i {
            display: inline-block;
            width: 10px;
            height: 40px;
            background: url("http://i1.fuimg.com/671541/9c8b8597f8f04603.png")
              no-repeat left center;
            vertical-align: 26px;
          }
          span {
            display: inline-block;
            width: calc(100% - 36px);
            padding-left: 26px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .header-2 {
      .title {
        margin-top: 70px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        margin-top: 30px;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        & > div {
          vertical-align: top;
          text-align-last: center;
          display: inline-block;
          width: 290px;
          height: 208px;
          margin-right: 10px;
          img {
            margin-top: 36px;
          }
          p {
            color: #fff;
            font-size: 16px;
            text-align: center;
            font-weight: bold;
            line-height: 30px;
            padding: 0 37px;
            margin-top: 11px;
          }
        }
        & > div:nth-child(1) {
          background: url("http://i1.fuimg.com/671541/509551055f3f9101.png")
            no-repeat;
        }
        & > div:nth-child(2) {
          background: url("http://i1.fuimg.com/671541/9c9cf0b15da187fb.png")
            no-repeat;
        }
        & > div:nth-child(3) {
          background: url("http://i1.fuimg.com/671541/73833a2c72e58d47.png")
            no-repeat;
        }
        & > div:nth-child(4) {
          background: url("http://i1.fuimg.com/671541/4b7b522793e241eb.png")
            no-repeat;
        }
      }
    }
  }
  .center {
    background: url("http://i1.fuimg.com/671541/c291a87526523204.jpg") no-repeat
      center top;
    margin-top: 50px;
    padding-bottom: 54px;
    .center-1 {
      width: 1190px;
      margin: 0 auto;
      .title {
        padding-top: 60px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      & > .content {
        width: 1189px;
        height: 526px;
        background: url("http://i1.fuimg.com/671541/ccf9f4d264c6f266.png")
          no-repeat;
        margin-top: 18px;
        display: flex;
        & > div {
          width: 50%;
          box-sizing: border-box;
          & > div:nth-child(1) {
            text-align: center;
            & > h3 {
              @include title;
              font-size: 22px;

              &::before {
                @include title-left;
                width: 19px;
                height: 26px;
                background: url("http://i2.tiimg.com/671541/2c43400683b0b952.png")
                  no-repeat center 18px;
              }
              &::after {
                @include title-right;
                width: 19px;
                height: 26px;
                background: url("http://i2.tiimg.com/671541/2c43400683b0b952.png")
                  no-repeat center 18px;
              }
            }
          }
          .content {
            padding-top: 40px;
            & > div {
              display: flex;
              height: 46px;
              margin-bottom: 30px;
              padding-left: 30px;
              & > div:nth-child(2) {
                padding-left: 22px;
                h4 {
                  font-size: 18px;
                  color: #9d7832;
                  font-weight: bold;
                  line-height: 1;
                  padding-top: 2px;
                }
                p {
                  font-size: 18px;
                  color: #000000;
                  line-height: 1;
                  margin-top: 8px;
                }
              }
            }
          }
          .content-1 {
            padding-top: 40px;
            & > div {
              display: flex;
              height: 46px;
              margin-bottom: 30px;
              padding-left: 140px;
              & > div:nth-child(2) {
                padding-left: 22px;
                h4 {
                  display: inline-block;
                  font-size: 18px;
                  color: #9d7832;
                  font-weight: bold;
                  line-height: 1;
                  padding-top: 2px;
                  margin-right: 16px;
                }
                p {
                  display: inline-block;
                  font-size: 18px;
                  color: #000000;
                  line-height: 1;
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 1190px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 40px;
    .title-box {
      text-align: center;
      .title {
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        margin-top: 20px;
      }
    }
    .bottom-2 {
      .title {
        margin-top: 50px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .apply-content {
        border: 1px solid #f2e2c4;
        margin-top: 30px;
        h3 {
          text-align: left;
          padding-left: 29px;
          height: 48px;
          line-height: 48px;
          color: #222222;
          font-size: 20px;
          border-bottom: 1px solid #f2e2c4;
          background: #f9f2e7;
        }
        & > div {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
  }
}
</style>