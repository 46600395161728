<template>
  <div class="box">
    <!-- 头部背景图 -->
    <div class="bgimg">
      <a href="javascript:;" class="applyimg" @click="goapply"></a>
      <!-- 如果您 -->
      <div class="content">
        <div>
          <p>空有一堆资源，苦于没有好的商品…</p>
          <p>产品被串货，项目利润低，被大厂商们绑架…</p>
          <p>没团队，缺产品，缺方案，怎么办？</p>
        </div>
      </div>
    </div>
    <!-- 厨盟项目合伙人 -->
    <div class="header">
      <div class="content">
        <div class="title">
          <h3>厨盟项目合伙人</h3>
        </div>
        <p>让你不再停滞在目前的困境中徘徊</p>
        <p style="margin-bottom: 25px">
          让你的人脉与资源最大限度的释放，让它快速变现
        </p>
        <p>尽管你可能不懂技术，团队能力弱</p>
        <p>甚至没有团队，可能没有好产品</p>
        <p>但厨盟项目合伙人就是为您量身定制的</p>
        <p>让您的资源与技术与产品与渠道打通，实现价值最大化</p>
      </div>
    </div>
    <div class="center">
      <div class="center-box">
        <div class="center-header">
          <!-- 招募范围 -->
          <div class="content">
            <h3>招募范围</h3>
            <div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner3/18.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>设计师人群</h4>
                  <p>智能建筑、弱电智能化等相关行业设计师人群</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner3/19.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>项目上起主导作用的个人/团队</h4>
                  <p>个人、团队或独立资源赋能者</p>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="../../assets/images/photo/partner3/6.png"
                    alt=""
                  />
                </div>
                <div>
                  <h4>企业</h4>
                  <p>设计单位、咨询公司、集成商/工程商</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 商品合作人三大特色 -->
        <div class="center-center">
          <div class="title">
            <h3>项目合伙人权益</h3>
          </div>
          <div class="content">
            <div>
              <h4>各类资料免费下载</h4>
              <p>解决方案、产品介绍、厂商授权各种证书、证明资质等</p>
            </div>
            <div>
              <h4>线上线下团队全方位技术支持</h4>
              <p>方案设计、投标文件技术支持各子系统报价</p>
            </div>
            <div>
              <h4>项目报备，互惠共赢</h4>
              <p>聚百亿需求 享金牌特权</p>
            </div>
          </div>
        </div>
        <!-- 项目合伙人价值  -->
        <div class="center-bottom">
          <div class="title">
            <h3>项目合伙人价值</h3>
          </div>
          <div class="content">
            <div>
              <div>
                <img
                  src="../../assets/images/photo/partner3/15.png"
                  alt=""
                />
              </div>
              <div>
                <h3>资源增值变现</h3>
                <p>整合平台营销、建设、技术、金融资源，轻松收益</p>
              </div>
            </div>
            <div>
              <div>
                <img
                  src="../../assets/images/photo/partner3/1.png"
                  alt=""
                />
              </div>
              <div>
                <h3>多业态、全系统、多品牌的解决方案</h3>
                <p>提供技术资料的下载及使用</p>
              </div>
            </div>
            <div>
              <div>
                <img
                  src="../../assets/images/photo/partner3/11.png"
                  alt=""
                />
              </div>
              <div>
                <h3>线上、线下专业团队的全方位技术支持</h3>
                <p>为项目合伙人进行技术赋能</p>
              </div>
            </div>
            <div>
              <div>
                <img
                  src="../../assets/images/photo/partner3/12.png"
                  alt=""
                />
              </div>
              <div>
                <h3>项目规划、设计阶段的品牌推荐</h3>
                <p>助力品牌成长，提升品牌竞争力</p>
              </div>
            </div>
            <div>
              <div>
                <img
                  src="../../assets/images/photo/partner3/7.png"
                  alt=""
                />
              </div>
              <div>
                <h3>利益共赢生态圈</h3>
                <p>打造项目合伙人、品牌厂商、厨盟平台等多方之间紧密合作</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- 申请流程 -->
      <div class="title-box">
        <div class="title">
          <h3>申请流程</h3>
        </div>
        <div class="content">
          <img src="../../assets/images/photo/partner3/2.png" alt="" />
        </div>
      </div>
      <!-- 申请加盟 -->
      <div class="bottom-2">
        <div class="title" id="apply">
          <h3>申请加盟</h3>
        </div>
        <div class="apply-content">
          <h3>填写加盟信息</h3>
          <div>
            <partnerform-1 :type="1"></partnerform-1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnerform1 from "./partnerform1.vue";

export default {
  components: { partnerform1 },

  data() {
    return {};
  },
  methods: {
    goapply() {
      this.$el.querySelector(`#apply`).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "center", // 上边框与视窗顶部平齐。默认值
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin title-left {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("../../assets/images/photo/partner4/left.png") no-repeat;
  margin-right: 20px;
}
@mixin title-right {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("../../assets/images/photo/partner4/right.png") no-repeat
    center;
  margin-left: 20px;
}
@mixin title {
  color: #222222;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}
.box {
  .bgimg {
    position: relative;
    width: 100%;
    height: 600px;
    background: url("../../assets/images/photo/partner3/13.jpg") no-repeat
      center top;
    & > .applyimg {
      display: inline-block;
      width: 294px;
      height: 64px;
      background: url("../../assets/images/photo/partner4/9.png")
        no-repeat;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      top: 335px;
      left: 50%;
      margin-left: -147px;
    }
    & > .content {
      display: inline-block;
      width: 1190px;
      height: 329px;
      background: url("../../assets/images/photo/partner3/4.jpg")
        no-repeat;
      position: absolute;
      padding-top: 60px;
      top: 436px;
      left: 50%;
      margin-left: -595px;
      text-align: center;
      div {
        font-size: 20px;
        color: #9d7832;
        text-align: center;
        margin-top: 4px;
        line-height: 44px;
        font-weight: bold;
      }
    }
  }
  .header {
    width: 1190px;
    margin: 0 auto;
    background-image: url("../../assets/images/photo/partner3/10.png");
    height: 409px;
    margin-top: 150px;
    .content {
      text-align: right;
      padding: 85px 42px 60px;
      .title {
        h3 {
          @include title;
          color: #eed7a4;
          margin-bottom: 28px;

          &::before {
            @include title-left;
            background: url("../../assets/images/photo/partner3/20.png")
              no-repeat;
          }
          &::after {
            @include title-right;
            background: url("http://i1.fuimg.com/671541/2c73b6dac445061b.png")
              no-repeat;
          }
        }
      }
      p {
        color: #ffffff;
        font-size: 16px;
        line-height: 30px;
        text-align: right;
        position: relative;
      }
    }
  }
  .center {
    margin-top: 200px;
    margin-bottom: 170px;
    height: 1178px;
    background: url("../../assets/images/photo/partner3/3.jpg") no-repeat
      center top;
    .center-box {
      width: 1190px;
      margin: 0 auto;
      padding-bottom: 55px;
      .center-header {
        margin-top: -150px;
        display: inline-block;
        width: 100%;
        background: url("../../assets/images/photo/partner3/20.png")
          no-repeat center top;
        padding: 60px 40px 95px;
        box-sizing: border-box;
        .content {
          h3 {
            width: 100%;
            text-align: center;
            @include title;
            margin-bottom: 20px;
            &::before {
              @include title-left;
            }
            &::after {
              @include title-right;
            }
          }
          & > div {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;

            & > div {
              padding: 32px 0px 0px 23px;
              display: inline-block;
              width: 330px;
              height: 94px;
              background: url("../../assets/images/photo/partner3/5.png")
                no-repeat;
              display: flex;
              div:nth-child(1) {
                padding-right: 20px;
              }
              div:nth-child(2) {
                h4 {
                  line-height: 1;
                  font-size: 16px;
                  font-weight: bold;
                  color: #6a4c19;
                  margin-top: 0;
                  margin-bottom: 11px;
                }
                p {
                  color: #6a4c19;
                  line-height: 24px;
                  font-size: 16px;
                }
              }
            }
            div:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .center-center {
        .title {
          margin-top: 70px;
          text-align: center;
          h3 {
            @include title;
            &::before {
              @include title-left;
            }
            &::after {
              @include title-right;
            }
          }
        }
        .content {
          display: flex;
          justify-content: space-around;
          div {
            flex: 1;

            height: 428px;
            text-align: center;
            margin: 0 33px;
            margin-top: 50px;
            h4 {
              text-align: center !important;
              font-size: 20px;
              color: #ffffff;
              font-weight: bold;
              line-height: 1;
              margin-top: 180px;
              margin-bottom: 12px;
            }
            p {
              text-align: center !important;
              font-size: 16px;
              color: #ffffff;
              line-height: 30px;
              padding: 0 82px;
            }
          }
          & > div:nth-child(1) {
            background: url("../../assets/images/photo/partner3/9.png")
              no-repeat;
            background-size: 100%;
          }
          & > div:nth-child(2) {
            background: url("../../assets/images/photo/partner3/8.png")
              no-repeat;
            background-size: 100%;
          }
          & > div:nth-child(3) {
            background: url("../../assets/images/photo/partner3/16.png")
              no-repeat;
            background-size: 100%;
          }
        }
      }
      .center-bottom {
        margin-top: 23px;
        .title {
          text-align: center;
          h3 {
            @include title;
            &::before {
              @include title-left;
            }
            &::after {
              @include title-right;
            }
          }
        }
        .content {
          width: 100%;
          height: 400px;
          background: #fff
            url("../../assets/images/photo/partner3/14.png") no-repeat
            638px 80px;
          margin-top: 30px;
          padding-top: 50px;
          box-shadow: 0 0 25px #e9e9e9;
          & > div {
            display: flex;
            height: 45px;
            margin-bottom: 30px;
            div:nth-child(1) {
              padding-left: 90px;
              img {
                vertical-align: middle;
              }
            }
            div:nth-child(2) {
              margin-left: 20px;
              h3 {
                font-size: 20px;
                line-height: 1;
                color: #222222;
                font-weight: bold;
              }
              p {
                font-size: 16px;
                line-height: 1;
                color: #9d7832;
                font-weight: bold;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 1190px;
    margin: 0 auto;
    margin-bottom: 40px;
    .title-box {
      text-align: center;
      .title {
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        margin-top: 20px;
      }
    }
    .bottom-2 {
      .title {
        margin-top: 50px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .apply-content {
        border: 1px solid #f2e2c4;
        margin-top: 30px;
        h3 {
          text-align: left;
          padding-left: 29px;
          height: 48px;
          line-height: 48px;
          color: #222222;
          font-size: 20px;
          border-bottom: 1px solid #f2e2c4;
          background: #f9f2e7;
        }
        & > div {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
  }
}
</style>