<template>
  <div>
    <div class="box">
      <div class="box-card">
        <div>
          <span
            v-text="
              state == 'add'
                ? '添加加盟信息'
                : state == 'update'
                ? '修改加盟信息'
                : state == 'anew'
                ? '重新提交加盟信息'
                : ''
            "
          ></span>
          <el-button type="primary" @click="getMyaudit"
            >已提交加盟信息</el-button
          >
        </div>

        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="ruleForm.companyName"></el-input>
          </el-form-item>
          <el-form-item label="公司图标">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :on-preview="logohandlePreview"
              :on-remove="logohandleRemove"
              :on-success="logohandleSuccess"
              :on-progress="logohandlePro"
              :file-list="fileList"
              v-loading='loading'
              :limit="1"
              list-type="picture"
              name="upload_file"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="营业执照">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :on-preview="licensehandlePreview"
              :on-remove="licensehandleRemove"
              :on-success="licensehandleSuccess"
              :file-list="fileList"
              :on-progress="logohandlePros"
              v-loading='loadings'
              :limit="1"
              list-type="picture"
              name="upload_file"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="营业执照注册号" prop="licenseNumber">
            <el-input v-model="ruleForm.licenseNumber"></el-input>
          </el-form-item>
          <el-form-item
            label="申请类型"
            prop="type"
            v-if="type == 1 && state == 'add'"
          >
            <el-radio-group v-model="ruleForm.type">
              <el-radio label="1">厂商</el-radio>
              <el-radio label="3">经销商</el-radio>
              <!-- <el-radio  label="4">餐饮公司</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="售卖类型"
            prop="sellType"
            v-if="ruleForm.type == 1 || ruleForm.type == 3"
          >
            <el-select v-model="ruleForm.sellType" placeholder="请选择售卖类型">
              <el-option label="厨具" value="1"></el-option>
              <el-option label="洗化" value="2"></el-option>
              <el-option label="粮油" value="3"></el-option>
              <el-option label="生鲜" value="4"></el-option>
              <el-option label="酒店" value="5"></el-option>
              <el-option label="商厨原材" value="6"></el-option>
              <el-option label="其他" value="7"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区" prop="region">
            <el-cascader
              ref="cascaderAddr"
              placeholder="请选择地区"
              v-model="ruleForm.region"
              :options="options"
              @change="regionhandleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
          <el-form-item label="法人姓名" prop="person">
            <el-input v-model="ruleForm.person"></el-input>
          </el-form-item>
          <el-form-item label="法人证件号" prop="personNumber">
            <el-input v-model="ruleForm.personNumber"></el-input>
          </el-form-item>
          <el-form-item label="公司电话" prop="companyPhone">
            <el-input v-model="ruleForm.companyPhone"></el-input>
          </el-form-item>
          <el-form-item
            v-if="ruleForm.type != 2"
            label="银行开户许可证"
            prop="bankLicence"
          >
            <el-input v-model="ruleForm.bankLicence"></el-input> </el-form-item
          ><el-form-item
            v-if="ruleForm.type != 2"
            label="组织结构代码证"
            prop="organizationCode"
          >
            <el-input
              v-model="ruleForm.organizationCode"
            ></el-input> </el-form-item
          ><el-form-item
            v-if="ruleForm.type != 2"
            label="银行卡号"
            prop="bankId"
          >
            <el-input v-model="ruleForm.bankId"></el-input>
          </el-form-item>

          <el-form-item v-if="isShow">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              v-text="
                state == 'add'
                  ? '立即添加'
                  : state == 'update'
                  ? '立即修改'
                  : state == 'anew'
                  ? '重新提交'
                  : '提交'
              "
            ></el-button>
          </el-form-item>
          <el-form-item v-else>
            <el-button
              disabled
              type="primary"
              @click="submitForm('ruleForm')"
              v-text="
                state == 'add'
                  ? '立即添加'
                  : state == 'update'
                  ? '立即修改'
                  : state == 'anew'
                  ? '重新提交'
                  : '提交'
              "
            ></el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
    <el-dialog
      title="我的加盟信息"
      :visible.sync="dialogTableVisible"
      class="Myaudit"
      width="70%"
    >
      <div>
        <el-button type="primary" @click="updateMyaudit">修改</el-button>
        <el-button type="primary" @click="anewMyaudit">重新提交</el-button>
        <el-button type="danger" @click="delectMyaudit">删除</el-button>
      </div>
      <div>
        <span>公司名称:</span><span v-text="Myaudit.companyName"></span>
      </div>
      <div>
        <span>公司图标:</span> <img :src="Myaudit.companyLogo" alt="" />
      </div>
      <div><span>营业执照:</span><img :src="Myaudit.licenseImg" alt="" /></div>
      <div>
        <span>营业执照注册号:</span><span v-text="Myaudit.licenseNumber"></span>
      </div>
      <div><span>详细地址:</span><span v-text="Myaudit.address"></span></div>
      <div><span>法人姓名:</span><span v-text="Myaudit.person"></span></div>
      <div>
        <span>法人电话:</span><span v-text="Myaudit.personNumber"></span>
      </div>
      <div>
        <span>公司电话:</span><span v-text="Myaudit.companyPhone"></span>
      </div>
      <div>
        <span>银行开户许可证:</span
        ><span v-text="Myaudit.organizationCode"></span>
      </div>
      <div>
        <span>组织结构代码证:</span><span v-text="Myaudit.companyName"></span>
      </div>
      <div><span>银行卡号:</span><span v-text="Myaudit.bankId"></span></div>
      <div><span>备注:</span><span v-text="Myaudit.message"></span></div>
      <div>
        <span>申请类型:</span
        ><span
          v-text="
            Myaudit.type == 1
              ? '厂商'
              : Myaudit.type == 2
              ? '客户'
              : Myaudit.type == 3
              ? '经销商'
              : Myaudit.type == 4
              ? '餐饮公司'
              : '未知'
          "
        ></span>
      </div>
      <div>
        <span>审核状态:</span
        ><span
          v-text="
            Myaudit.status == 0
              ? '待审核'
              : Myaudit.status == 1
              ? '通过'
              : Myaudit.status == 2
              ? '失败'
              : '未知'
          "
        ></span>
      </div>
      <div><span>所在地区:</span><span v-text="Myaudit.areaName"></span></div>
      <div><span>创建时间:</span><span v-text="Myaudit.createTime"></span></div>
      <div><span>修改时间:</span><span v-text="Myaudit.updateTime"></span></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  props: ["type"],
  data() {
    return {
      // 记录当前处于添加/删除/更新
      state: "add",
      // 地区
      options: JSON.parse(sessionStorage.getItem("allAreaData")),
      // 文件上传路径
      uploadUrl: "http://www.jujiangmofang.cn/kitchenac/file/upload",
      // 我的公司审核信息模块显示隐藏
      dialogTableVisible: false,
      // 我的公司审核信息
      Myaudit: {},
      fileList: [],
      loading:false,
      loadings:false,
      isShow:false,
      buttonDis:false,
      buttons:false,
      ruleForm: {
        companyName: "",
        companyLogo: "",
        licenseImg: "",
        licenseNumber: "",
        region: "",
        address: "",
        person: "",
        personNumber: "",
        companyPhone: "",
        type: "",
        bankLicence: "",
        organizationCode: "",
        bankId: "",
        sellType: "",
      },
      rules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        bankLicence: [
          { required: true, message: "请输入银行开户许可证", trigger: "blur" },
        ],
        organizationCode: [
          { required: true, message: "请输入组织结构代码证", trigger: "blur" },
        ],
        bankId: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
        ],
        person: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        personNumber: [
          { required: true, message: "请输入法人证件号", trigger: "blur" },
        ],
        companyPhone: [
          { required: true, message: "请输入公司电话", trigger: "blur" },
        ],
        licenseNumber: [
          { required: true, message: "请输入营业执照注册号", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        region: [{ required: true, message: "请选择地区", trigger: "change" }],
        sellType: [
          { required: true, message: "请选择售卖类型", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 提交表单
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 如果当前选中餐饮公司则提交表单的type为4
          // 如果当前选中城市合伙人提交表单的type为5
          // 如果当前选中客户提交表单的type为2
          if (this.type == 2) {
            this.ruleForm.type = 4;
          }
          if (this.type == "kehu") {
            this.ruleForm.type = 2;
          }
          if (this.type == 5) {
            this.ruleForm.type = 5;
          }
          let ruleForm = { ...this.ruleForm };
          ruleForm.provinceId = ruleForm.region[0];
          ruleForm.cityId = ruleForm.region[1];
          ruleForm.countyId = ruleForm.region[2];
          delete ruleForm.region;
          if (ruleForm.type == 2) {
            delete ruleForm.bankLicence;
            delete ruleForm.organizationCode;
            delete ruleForm.bankId;
            delete ruleForm.sellType;
          }

          if (this.state == "add") {
            const res = await this.$http({
              method: "post",
              url: "front/checkCompany/save",
              data: {
                ...ruleForm,
              },
            });

            if (res.data.status == 200) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
            }
          } else if (this.state == "update") {
            console.log(this.ruleForm);
            const res = await this.$http({
              method: "post",
              url: "front/checkCompany/update",
              data: {
                id: this.Myaudit.id,
                ...ruleForm,
              },
            });
            if (res.data.status == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
          } else if (this.state == "anew") {
            const res = await this.$http({
              method: "post",
              url: "front/checkCompany/checkAgain",
              data: {
                id: this.Myaudit.id,
                ...ruleForm,
              },
            });

            if (res.data.status == 200) {
              this.$message({
                message: "重新提交成功",
                type: "success",
              });
            }
          }
        }
      });
    },
    // 修改我的公司审核信息
    updateMyaudit() {
      this.dialogTableVisible = false;
      this.state = "update";
      this.ruleForm = this.Myaudit;
      this.ruleForm.region = [];
      // this.reload();
    },
    // 更新我的公司审核信息
    anewMyaudit() {
      this.dialogTableVisible = false;
      this.state = "anew";
      this.ruleForm = this.Myaudit;
      this.ruleForm.region = [];
      // this.reload();
    },
    // 删除我的公司审核信息
    async delectMyaudit() {
      const res = await this.$http({
        method: "post",
        url: "front/checkCompany/delete",
        data: {
          id: this.Myaudit.id,
        },
      });
      if (res.data.status == 200) {
        this.reload();
        this.dialogTableVisible = false;
        this.$message({
          message: "删除成功",
          type: "success",
        });
      }
    },
    //获取我的公司审核信息
    async getMyaudit() {
      const res = await this.$http({
        method: "get",
        url: "front/checkCompany/findByUserId",
      });
      if (res.data.status == 200) {
        this.dialogTableVisible = true;
        this.Myaudit = res.data.data;
      }
    },
    //logo上传时
    logohandlePro(event,file,fileList){
          this.loading = true;    
    },
    logohandlePros(event,file,fileList){
          this.loadings = true;    
    },
    // logo上传函数
    logohandleRemove(file, fileList) {
      this.ruleForm.companyLogo = "";
      this.isShow = false;
      this.buttonDis = false;
      this.buttons = false;
    },
    logohandlePreview(file) {},
    logohandleSuccess(k, v, list) {
      this.$message({
        message: "logo上传成功",
        type: "success",
      });
      this.buttonDis=true;
      if(this.buttons==true){
        this.isShow = true
      };
      this.loading = false;
      this.ruleForm.companyLogo = `${k.data.url}${k.data.uri}`;
    },
    // 营业执照上传函数
    licensehandleRemove(file, fileList) {
      this.ruleForm.licenseImg = "";
      this.isShow = false;
      this.buttonDis = false;
      this.buttons = false;
    },
    licensehandlePreview(file) {},
    licensehandleSuccess(k, v, list) {
      this.$message({
        message: "营业执照上传成功",
        type: "success",
      });
      this.buttons=true;
      if(this.buttonDis==true){
        this.isShow=true
      };
      this.loadings = false;
      this.ruleForm.licenseImg = `${k.data.url}${k.data.uri}`;
    },
    // 地区选择change
    regionhandleChange(value) {
      // 显示lable
      // console.log(this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels);
    },
  },
  created() {
    console.log("tyep", this.type);
    if (this.type == "kehu") {
      this.ruleForm.type = 2;
    }
  },
};
</script>

<style lang="scss" scoped>
@mixin title {
  font-size: 18px;
  margin: 10px;
}
.el-dialog__wrapper {
  min-width: 1000px;
}
.box {
  width: 100%;
  min-width: 1000px;
  .box-card {
    width: 50%;
    margin: 0 auto;
    & ::v-deep .el-button {
      display: inline-block;
      color: #ffffff;
      font-size: 18px;
      width: 192px;
      height: 48px;
      background: url(https://image01.homedo.com/Files/Images/cms/www/20190828/5448770380995588460_pic1.png)
        no-repeat;
      text-align: center;
      margin: 0 auto;
      outline: none;
      border: 0;
    }
    & ::v-deep .el-input__inner {
      border: 1px solid #f2e2c4;
    }
    & > div:nth-child(1) {
      display: flex;
      justify-content: space-between;
      span {
        font-weight: 600;
        line-height: 50px;
      }
      .el-button {
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
    .el-card__body {
      p:nth-child(2) {
        @include title;
      }
    }
  }
}
.Myaudit {
  div {
    display: flex;
    align-items: center;
    padding: 5px;
    span:nth-child(1) {
      display: inline-block;
      width: 150px;
      text-align: right;
    }
    span:nth-child(2) {
      display: inline-block;
      margin-left: 20px;
    }
    img {
      width: 400px;
      margin-left: 20px;
    }
  }
  div:nth-child(1) {
    display: flex;
    justify-content: flex-end;
  }
}
</style>