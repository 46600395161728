<template>
  <div class="box">
    <!-- 头部背景图 -->
    <div class="bgimg" v-if="active == 1 || active == 'kehu'">
      <a href="javascript:;" class="applyimg" @click="goapply"></a>
      <!-- 如果您 -->
      <div class="content">
        <h3>如果您</h3>
        <div>
          <p>
            <i></i>
            <span>产品优势明显，营销能力薄弱</span>
            <span
              >在弱电智能化领域深耕多年，产品本地化优势明显，放眼全国则无人问津。</span
            >
          </p>
          <p>
            <i></i>
            <span>海外市场强势，国内市场薄弱</span>
            <span
              >海外市场风风火火，国内市场业绩惨淡，想了很多方法，花了巨大成本，推广效果依旧不明显。</span
            >
          </p>
          <p>
            <i></i>
            <span>现有环境饱和，增量市场匮乏</span>
            <span>长期在舒适区，销量稳定，想开拓增量市场，却无从下手。</span>
          </p>
          <p>
            <i></i>
            <span>大牌代理在手，销售渠道匮乏</span>
            <span
              >好不容易争取到的大牌代理，还有备货垫资在手，却没有好的销售通路。</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="header" v-if="active == 1 || active == 'kehu'">
      <!--  厨盟商品合伙人的优势 -->
      <div class="header-1">
        <div class="title">
          <h3>厨盟商品合伙人的优势</h3>
        </div>
        <div class="content">
          <div>
            <div>
              <h4>让具有先进技术与商业模式的优秀企业</h4>
              <p>不再为市场能力的短板而烦恼</p>
            </div>
            <div>
              <h4>让拥有大牌代理商的企业</h4>
              <p>不再为销售通路而苦恼</p>
            </div>
          </div>
          <p>快速抢占时间与机遇窗口，实现企业的跨越式发展！</p>
        </div>
      </div>
      <!-- 厨盟商品合伙人介绍  -->
      <div class="header-2">
        <div class="title">
          <h3>厨盟商品合伙人介绍</h3>
          <div class="content">
            <p>
              <i></i
              ><span
                >竭诚为行业内设备制造商、软件服务商提供产品解决方案的营销顾问服务；</span
              >
            </p>
            <p>
              <i></i
              ><span>集产品销售与服务配套为一体的全程化、一站式的服务；</span>
            </p>
            <p>
              <i></i
              ><span
                >开创互联网时代专业化分工、生态式合作、战略化协同的全新模式；</span
              >
            </p>
            <p>
              <i></i
              ><span
                >同时，厨盟也面向全国开展知名品牌的属地化供货战略模式，</span
              >
            </p>
            <p><span>即厨盟当地的订单交由当地的厂家或代理商发货，</span></p>
            <p><span>如您有丰富的货源也可联系成为我们的合伙人。</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="center" v-if="active == 1 || active == 'kehu'">
      <div class="center-box">
        <div class="center-header">
          <!-- 招募范围 -->
          <div class="content">
            <h3>招募范围</h3>
            <p>
              <i></i>
              <span>有好产品好资质但没好渠道的供应商</span>
            </p>
            <p>
              <i></i>
              <span>知名品牌在三四线城市的代理商</span>
            </p>
          </div>
        </div>
        <!-- 商品合作人三大特色 -->
        <div class="center-center">
          <div class="title">
            <h3>商品合伙人三大特色</h3>
          </div>
          <div class="content">
            <div>
              <img
                src="http://q5355.bvimg.com/10063/8ba244fa29dce45a.png"
                alt=""
              />
              <h3>市场推广</h3>
              <h4>全方位、结构化的市场推广能力</h4>
              <p>
                <i></i>
                <span>百万级别行业，流量精准送达；</span>
              </p>
              <p>
                <i></i>
                <span>共享智能建筑行业顶级盛会；</span>
              </p>
              <p>
                <i></i>
                <span>线下过万人活动，影响力深广；</span>
              </p>
              <p>
                <i></i>
                <span>上万家顶级集成商长期合作；</span>
              </p>
              <p>
                <i></i>
                <span>行业精准数据，智慧触达用户。</span>
              </p>
            </div>
            <div>
              <img
                src="http://q5355.bvimg.com/10063/6b3d37dfc227f961.png"
                alt=""
              />
              <h3>营销转化</h3>
              <h4>全程、全面的业务转化与支持能力</h4>
              <p>
                <i></i>
                <span>100个遍布全国的服务网点全程参与业务对接与商务转化；</span>
              </p>
              <p>
                <i></i>
                <span
                  >与几十家银行和类金融机构形成供应链金融的战略合作，助力业务腾飞；</span
                >
              </p>
              <p>
                <i></i>
                <span
                  >一站式物流服务保障，上千个合伙人团队全程协助售前售后工作。</span
                >
              </p>
            </div>
            <div>
              <img
                src="http://q5355.bvimg.com/10063/79e3189c9f9bfabd.png"
                alt=""
              />
              <h3>生态合作</h3>
              <h4>新型的产业互联网生态合作模式</h4>
              <p>
                <i></i>
                <span>合作模式多样：股权投资、联合经营、项目协同开发；</span>
              </p>
              <p>
                <i></i>
                <span>付费方式灵活：营销费用与业绩挂钩；</span>
              </p>
              <p>
                <i></i>
                <span>全新模式赋能：市场推广、资源赋能、代理产品；</span>
              </p>
              <p>
                <i></i>
                <span>市场入口丰富：全面助力合作伙伴，加快互联网进程。</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" :style="{ marginTop: active != 1 ? '140px' : '0px' }">
      <!-- 申请流程 -->
      <div class="bottom-1">
        <div class="title">
          <h3>申请流程</h3>
        </div>
        <div class="content">
          <img src="http://q5355.bvimg.com/10063/339b3848419aa182.png" alt="" />
        </div>
      </div>
      <!-- 申请加盟 -->
      <div class="bottom-2">
        <div class="title" id="apply">
          <h3>申请加盟</h3>
        </div>
        <div class="apply-content">
          <h3>填写加盟信息</h3>
          <div>
            <partnerform :type="active"></partnerform>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partnerform from "./partnerform.vue";
export default {
  components: { partnerform },
  props: ["active"],
  data() {
    return {};
  },
  methods: {
    goapply() {
      this.$el.querySelector(`#apply`).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "center", // 上边框与视窗顶部平齐。默认值
      });
    },
  },
  created() {
    console.log(this.active);
  },
};
</script>

<style lang="scss" scoped>
@mixin title-left {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("http://q5355.bvimg.com/10063/70026ad9bf899a02.png") no-repeat;
  margin-right: 20px;
}
@mixin title-right {
  content: "";
  display: inline-block;
  width: 32px;
  height: 21px;
  background: url("http://q5355.bvimg.com/10063/7c6eecb4ee1a53ee.png") no-repeat
    center;
  margin-left: 20px;
}
@mixin title {
  color: #222222;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}
.box {
  .bgimg {
    position: relative;
    width: 100%;
    height: 600px;
    background: url("http://q5355.bvimg.com/10063/89a242f46ae7ffca.jpg") no-repeat
      center top;
    & > .applyimg {
      display: inline-block;
      width: 294px;
      height: 64px;
      background: url("http://q5355.bvimg.com/10063/c7324d18d915f907.png")
        no-repeat;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      top: 335px;
      left: 50%;
      margin-left: -147px;
    }
    & > .content {
      display: inline-block;
      width: 1190px;
      height: 329px;
      background: url("http://q5355.bvimg.com/10063/b8a53355a84f9029.png")
        no-repeat;
      position: absolute;
      padding-top: 60px;
      top: 436px;
      left: 50%;
      margin-left: -590px;
      text-align: center;
      h3 {
        display: inline-block;
        color: #222222;
        font-size: 32px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 40px;
        &::before {
          @include title-left;
        }
        &::after {
          @include title-right;
        }
      }
      div {
        padding: 0 70px 48px;
        p {
          text-align: left;
          margin-bottom: 30px;
          line-height: 22px;
          i {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #3768cc;
            margin-right: 20px;
            vertical-align: middle;
            font-style: normal;
          }
          & > span:nth-child(2) {
            font-size: 20px;
            color: #9d7832;
            font-weight: bold;
            margin-right: 20px;
            line-height: 22px;
            vertical-align: middle;
          }
          & > span:nth-child(3) {
            font-size: 16px;
            color: #222222;
            text-align: left;
            margin-bottom: 30px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .header {
    margin-bottom: 180px;
    .header-1 {
      margin-top: 280px;
      .title {
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        width: 1190px;
        margin: 0 auto;
        height: 302px;
        background: url("http://q5355.bvimg.com/10063/cdd7e89c35139ad7.png")
          no-repeat center 70px;
        padding-bottom: 70px;
        & > div:nth-child(1) {
          display: flex;
          justify-content: space-between;
          width: 950px;
          margin: 0 auto;
          position: relative;
          top: 100px;
          div {
            width: 452px;
            text-align: center;
            h4 {
              font-size: 20px;
              color: #f9f2e7;
              line-height: 1;
              text-align: center;
              margin-top: 33px;
              font-weight: normal;
            }
            p {
              font-size: 20px;
              color: #f9f2e7;
              line-height: 1;
              text-align: center;
              font-weight: bold;
              margin-top: 18px;
            }
          }
        }
        & > p {
          width: 100%;
          padding-top: 205px;
          text-align: center;
          font-size: 26px;
          color: #4c5c99;
          line-height: 1;
          font-weight: bold;
        }
      }
    }
    .header-2 {
      width: 1190px;
      margin: 0 auto;

      margin-top: 50px;
      margin-bottom: 40px;
      .title {
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        display: inline-block;
        width: 100%;
        background: url("http://q5355.bvimg.com/10063/143e8c3df04db2a7.png")
          no-repeat;
        padding: 50px 50px 88px;
        box-sizing: border-box;
        margin-top: 40px;
        p {
          line-height: 38px;
          text-align: left;
          padding-left: 32px;
          margin-bottom: 12px;
          position: relative;
          i {
            display: inline-block;
            width: 12px;
            height: 12px;
            background: #3768cc;
            position: absolute;
            left: 0;
            top: 13px;
            font-style: normal;
          }
          span {
            color: #222222;
            font-size: 20px;
          }
        }
      }
    }
  }
  .center {
    background: url("http://q5355.bvimg.com/10063/56114433c258ddab.jpg") no-repeat
      center top;
    .center-box {
      width: 1190px;
      margin: 0 auto;
      padding-bottom: 35px;
      .center-header {
        margin-top: -150px;
        display: inline-block;
        width: 100%;
        background: url("http://q5355.bvimg.com/10063/11befa2381e29a27.jpg")
          no-repeat left top;
        padding: 80px 70px 95px;
        box-sizing: border-box;
        .content {
          h3 {
            @include title;
            color: #e1bb6c;
            margin-bottom: 20px;
            &::before {
              @include title-left;
              background: url("http://q5355.bvimg.com/10063/70026ad9bf899a02.png")
                no-repeat;
            }
            &::after {
              @include title-right;
              background: url("http://q5355.bvimg.com/10063/7c6eecb4ee1a53ee.png")
                no-repeat;
            }
          }
          p {
            color: #ffffff;
            font-size: 26px;
            line-height: 50px;
            text-align: left;
            i {
              display: inline-block;
              width: 12px;
              height: 12px;
              background: #3768cc;
              vertical-align: middle;
              margin-right: 20px;
            }
          }
        }
      }
      .center-center {
        .title {
          margin-top: 70px;
          text-align: center;
          h3 {
            @include title;
            &::before {
              @include title-left;
            }
            &::after {
              @include title-right;
            }
          }
        }
        .content {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          div {
            flex: 1;
            margin: 0 8px;  
            img {
              width: 100%;
            }
            h3 {
              display: inline-block;
              width: 315px;
              font-size: 26px;
              font-weight: bold;
              color: #9d7832;
              line-height: 1;
              text-align: center;
              margin-top: 30px;
            }
            h4 {
              text-align: left;
              font-size: 20px;
              color: #9d7832;
              font-weight: bold;
              line-height: 1;
              margin-top: 24px;
              margin-bottom: 23px;
            }
            p {
              text-align: left;
              font-size: 14px;
              color: #222222;
              line-height: 30px;
              padding-right: 40px;
              padding-left: 17px;
              position: relative;
              i {
                display: inline-block;
                width: 6px;
                height: 6px;
                background: #3768cc;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 12px;
                font-style: normal;
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 1190px;
    margin: 0 auto;
    margin-bottom: 40px;
    .bottom-1 {
      .title {
        margin-top: 50px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .content {
        margin: 20px 0;
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
    .bottom-2 {
      .title {
        margin-top: 50px;
        text-align: center;
        h3 {
          @include title;
          &::before {
            @include title-left;
          }
          &::after {
            @include title-right;
          }
        }
      }
      .apply-content {
        border: 1px solid #f2e2c4;
        margin-top: 30px;
        h3 {
          text-align: left;
          padding-left: 29px;
          height: 48px;
          line-height: 48px;
          color: #222222;
          font-size: 20px;
          border-bottom: 1px solid #f2e2c4;
          background: #f9f2e7;
        }
        & > div {
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
  }
}
</style>